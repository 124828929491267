import Pathmaster from "./Pathmaster/Pathmaster";

function App() {
  return (
    <>
      <Pathmaster></Pathmaster>
    </>
  );
}

export default App;
